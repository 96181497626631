import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Cell, Grid } from "./grid";
import useInterval from "../interval";
import AnimatedImage from "./animatedimage";
import { motion } from "framer-motion";

class GridTracker {
    constructor(gridWidth, gridHeight) {
        this.gridWidth = gridWidth;
        this.gridHeight = gridHeight;
        this.gridTracker = Array.from({ length: gridHeight }, () => Array(gridWidth).fill(0));
    }

    occupyCells(x, y, cellWidth, cellHeight) {
        for (let i = y; i < y + cellHeight; i++) {
            for (let j = x; j < x + cellWidth; j++) {
                this.gridTracker[i][j] = 1;
            }
        }
    }

    findLargestUnoccupiedSpace() {
        let maxArea = 0;
        let bestFit = { cellWidth: 0, cellHeight: 0, x: -1, y: -1 };

        for (let i = 0; i < this.gridHeight; i++) {
            for (let j = 0; j < this.gridWidth; j++) {
                if (this.gridTracker[i][j] === 0) {
                    let width = 0, height = 0;
                    while (j + width < this.gridWidth && this.gridTracker[i][j + width] === 0) width++;
                    while (i + height < this.gridHeight && this.gridTracker[i + height][j] === 0) {
                        if (this.gridTracker[i + height].slice(j, j + width).some(cell => cell === 1)) break;
                        height++;
                    }

                    if (width * height > maxArea) {
                        maxArea = width * height;
                        bestFit = { cellWidth: width, cellHeight: height, x: j, y: i };
                    }
                }
            }
        }

        return bestFit;
    }
}

const GridImage = React.memo(({ image, x, y, width = 1, height = 1, onClick }) => (
    <Cell x={x} y={y} cellWidth={width} cellHeight={height}>
        <AnimatedImage
            onClick={onClick}
            src={image.url}
            alt={image.alt}
            style={{ width: "100%", height: "100%" }}
        />
    </Cell>
));

const ImageGrid = ({ images, width, height, shuffleTime = -1, onClickImage = () => false }) => {
    const gridRef = useRef(null);
    const [imageAspects, setImageAspects] = useState({});
    const [allocatedImages, setAllocatedImages] = useState([]);
    const [isShuffling, setIsShuffling] = useState(false);
    const [isFading, setIsFading] = useState(false);

    useEffect(() => {
        if (!images.length) return;

        const aspectMap = images.reduce((acc, image) => {
            const ar = Math.floor(image.aspect_ratio * 1000);
            if (!acc[ar]) {
                acc[ar] = [];
            }
            acc[ar].push(image);
            return acc;
        }, {});

        setImageAspects(prev => (JSON.stringify(prev) === JSON.stringify(aspectMap) ? prev : aspectMap));
    }, [images]);

    const shuffleImages = useCallback(async () => {
        if (isShuffling || !Object.keys(imageAspects).length || !gridRef.current) return;
        setIsShuffling(true);
        setIsFading(true);

        await new Promise(resolve => setTimeout(resolve, 300)); // Fade out
        setAllocatedImages([]);
        await new Promise(resolve => setTimeout(resolve, 300)); // Transition delay

        gridRef.current.clear();
        const newAllocatedImages = [];
        const aspectKeys = Object.keys(imageAspects);

        for (let i = 0; i < width * height && gridRef.current.hasAvailableSpace(); i++) {
            const bestAspect = aspectKeys.reduce((best, ar) => {
                const dimen = gridRef.current.getBestDimensionsForAspectRatio(ar / 1000);
                return dimen.width * dimen.height > best.size ? { ar, dimen, size: dimen.width * dimen.height } : best;
            }, { ar: null, dimen: null, size: 0 });

            if (!bestAspect.ar) break;

            const image = imageAspects[bestAspect.ar][Math.floor(Math.random() * imageAspects[bestAspect.ar].length)];
            gridRef.current.reserve(bestAspect.dimen.x, bestAspect.dimen.y, bestAspect.dimen.width, bestAspect.dimen.height);
            newAllocatedImages.push({ image, ...bestAspect.dimen });
        }

        setAllocatedImages(newAllocatedImages);
        setIsFading(false);
        setIsShuffling(false);
    }, [imageAspects, width, height, isShuffling]);

    useEffect(() => {
        shuffleImages();
    }, [imageAspects]);

    useInterval(() => shuffleImages(), shuffleTime > 0 ? shuffleTime * 1000 : null);

    return (
        <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: isFading ? 0 : 1 }}
            transition={{ duration: 0.3 }}
        >
            <Grid ref={gridRef} width={width} height={height} onClick={() => (!onClickImage() && shuffleImages())}>
                {allocatedImages.map((image, index) => (
                    <GridImage key={index} {...image} onClick={() => (!onClickImage() && shuffleImages())} />
                ))}
            </Grid>
        </motion.div>
    );
};

export default ImageGrid;