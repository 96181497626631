import React, {forwardRef, useImperativeHandle, useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {toast} from "react-toastify";

function getCookieVariable(variableName) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(variableName + '=')) {
            return decodeURIComponent(cookie.substring(variableName.length + 1));
        }
    }
    return null;
}

function setCookieVariable(variableName, variableValue, expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    const cookieValue = encodeURIComponent(variableValue) + '; expires=' + expirationDate.toUTCString() + '; path=/';
    document.cookie = variableName + '=' + cookieValue;
}

export function createUri(endpoint, session, params) {
    let paramString = "";
    // Iterate over the params and add them to the uri
    for (const [key, value] of Object.entries(params)) {
        if(value === undefined) continue;
        paramString += `&${key}=${encodeURIComponent(value)}`;
    }
    return `https://api.aiart.doubtech.com/${endpoint}?session=${session}${paramString}`;
}

export async function startSession() {
    let session = getCookieVariable("api.aiart.doubtech.com::session");

    if(!session) {
        const response = await fetch("https://api.aiart.doubtech.com/auth/start-session");
        const body = await response.json();
        window.sposession = body.session;
        setCookieVariable("api.aiart.doubtech.com::session", body.session, 7);
    }
    window.sposession = session;
    return session;
}

async function handleResult(result) {
    try {
        const text = await result.text();
        // try to parse the json safely
        try {
            return JSON.parse(text);
        } catch (e) {
            return {"status": result.status, "error": result.statusText, data: text}
        }
    } catch (e) {
        console.error(e, result);
        return {"error": e.toString()}
    }
}

export async function apiGet(endpoint, params={}) {
    try {
        const session = await startSession();
        const uri = createUri(endpoint, session, params);
        const result = await fetch(uri,
            {
                mode: 'cors',
                method: 'GET',
                cache: "no-cache"
            });
        return await handleResult(result);
    } catch (e) {
        console.error(e);
        return {"error": e.toString()}
    }
}

async function apiGetAuth(token, endpoint, params={}) {
    const session = await startSession();
    const uri = createUri(endpoint, session, {token: token, ...params});
    try {
        const headers = {
            mode: 'cors',
            method: "GET",
            cache: "no-cache",
            Authorization: `Bearer ${token}`
        };
        const result = await fetch(uri, headers);
        return await handleResult(result);
    } catch (e) {
        console.error(e);
        return {"error": e.toString()}
    }
}

async function apiPostAsync(endpoint, body, params={}, headers = {contentType: undefined, acceptType: undefined}) {
    try {
        const session = await startSession();
        const uri = createUri(endpoint, session, params);

        // If the body is an object convert it to a json string
        if(typeof body === "object") {
            body = JSON.stringify(body);
        }

        const result = await fetch(uri,
            {
                mode: 'cors',
                method: "POST",
                cache: "no-cache",
                body: body,
                ...(headers.contentType && { 'Content-Type': headers.contentType }),
                ...(headers.acceptType && { 'Accept': headers.acceptType }),
            });
        return await handleResult(result);
    } catch (e) {
        console.error(e)
        return {"error": e.toString()}
    }
}

async function apiPostAuthAsync(token, endpoint, body, params={}, headers = {contentType: undefined, acceptType: undefined}) {
    try {
        const session = await startSession();
        const uri = createUri(endpoint, session, params);

        // If the body is an object convert it to a json string
        if(typeof body === "object") {
            body = JSON.stringify(body);
        }

        const result = await fetch(uri, {
            mode: 'cors',
            method: "POST",
            cache: "no-cache",
            body: body,
            Authorization: `Bearer ${token}`,
            ...(headers.contentType && { 'Content-Type': headers.contentType }),
            ...(headers.acceptType && { 'Accept': headers.acceptType })
        });
        return await handleResult(result);
    } catch (e) {
        console.error(e)
        return {"error": e.toString()}
    }
}

export const useToken = () => {
    const { getAccessTokenSilently, user } = useAuth0();

    async function getToken() {
        try {
            if (window.apt_last_request) return window.apt_last_request;
            window.apt_last_request = await getAccessTokenSilently({
                authorizationParams: {
                    audience: "https://api.aiart.doubtech.com",
                    scopes: ['openid', 'profile', 'email']
                }
            });
            return window.apt_last_request;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    return {getToken, user, getAccessTokenSilently: getToken};
}

export const useEndpoint = (endpoint, headers=  {contentType: undefined, acceptType: undefined}) => {
    const { getToken, user } = useToken();

    async function fetch(params={}) {
        return await apiGet(endpoint, params);
    }

    async function fetchAuth(params={}) {
        const token = await getToken();
        return await apiGetAuth(token, endpoint, params);
    }

    async function post(body, params={}) {
        return await apiPostAsync(endpoint, body, params, headers);
    }

    async function postAuth(params={}) {
        const token = await getToken();
        return await apiPostAuthAsync(token, endpoint, undefined, params, headers);
    }

    async function postBody(body, params={}) {
        return await apiPostAsync(endpoint, body, params, headers);
    }

    async function postBodyAuth(body, params={}) {
        const token = await getToken();
        return await apiPostAuthAsync(token, endpoint, body, params, headers);
    }

    return {fetchAuth, fetch, user, postBody, postBodyAuth, post, postAuth};
}

export async function toastRequest(requestPromise,
                                   {
                                       startingMessage = "Submitted",
                                       createSuccessMessage = (content) => "Success!",
                                       createErrorMessage = (response) => "Error: " + response?.error
                                   }) {
    const toastHandle = toast(startingMessage, {type: "info"});
    toast.update(toastHandle, {autoClose: false, closeButton: true, closeOnClick: false, draggable: false, hideProgressBar: true, pauseOnHover: false, progress: undefined, type: "info"});
    const result = await requestPromise;
    if("error" in result)
    {
        // If createErrorMessage is not a function use the default
        if(typeof createErrorMessage !== "function") createErrorMessage = (response) => "Error: " + response?.error;
        toast.update(toastHandle, {autoClose: 3000, closeButton: true, closeOnClick: true, draggable: true, hideProgressBar: false, pauseOnHover: true, progress: undefined, type: "error", render: createErrorMessage(result)});
        return result;
    }
    if(typeof createSuccessMessage !== "function") createErrorMessage = (response) => "Success!";

    const successText = createSuccessMessage(result);
    toast.update(toastHandle, {
        render: successText,
        autoClose: 3000,
        closeButton: true,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        type: "success"
    });
    return result;
}
