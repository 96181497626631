// src/CodePreview.js
import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import "./codepreview.css";
import { toast } from "react-toastify";
import { MdContentCopy } from "react-icons/md";

const CodePreview = ({
                         children,
                         language,
                         title,
                         renderHeader = () => {},
                         renderFooter = () => {},
                         expanded = false,
                         code = undefined,
                         clipboardCode = undefined,
                     }) => {
    const [isExpanded, setIsExpanded] = useState(expanded); // State for content toggle

    const handleCopyClick = () => {
        navigator.clipboard.writeText(clipboardCode ?? code ?? children);
        toast.success("Code copied to clipboard");
    };

    return (
        <div className="code-box">
            <div className="code-title" onClick={() => setIsExpanded(!isExpanded)}>
                {title && <span><b>{title}</b> - </span>}
                <span style={{ color: "#989a7a" }}>{language}</span>
            </div>
            <button className="copy-button" onClick={handleCopyClick}>
                <MdContentCopy /> Copy
            </button>
            <div className={`code-content ${isExpanded ? "expanded" : "collapsed"}`}>
                {renderHeader && renderHeader()}
                {language === "plaintext" ? (
                    <pre className="plaintext-content">{code ?? children}</pre>
                ) : (
                    <SyntaxHighlighter language={language} style={dark}>
                        {code ?? children}
                    </SyntaxHighlighter>
                )}
                {renderFooter && renderFooter()}
            </div>
        </div>
    );
};

export default CodePreview;
