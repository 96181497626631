import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {Tab, Tabs} from "react-bootstrap";
import AddContentDialog from "./AddContentDialog";
import {MdAdd, MdCopyAll, MdNoteAdd, MdShare, MdVpnKey} from "react-icons/all";
import {MdApps, MdContentPaste, MdNewLabel} from "react-icons/md";
import {toast} from "react-toastify";
import ChatTab from "./tabs/ChatTab";
import AgentContentTab from "./tabs/AgentContentTab";
import GraphTab from "./tabs/GraphTab";
import AgentPropertiesTab from "./tabs/AgentProperties";
import PageHeader from "../../PageHeader/pageheadercontrol";
import useWindowDimensions from "../../../../hooks/WindowDimensions";
import {useEndpoint} from "../../../../hooks/api";
import {useLoading} from "../../loading";
import CreateApiKey from "./apikeys/CreateApiKey";
import AddApiKeyDialog from "./dialogs/AddApiKeyDialog";
import AgentCanvas from "../../../pages/gpt/canvas";

function AgentInfo(props) {
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const { fetchAuth: addContentRequest} = useEndpoint("gpt/add-content");
    const { fetchAuth: fetchAgentRequest } = useEndpoint("gpt/agent-info");
    const [modificationIndex, setModificationIndex] = useState(0);
    const defaultImage = '/img/headers/agent.png';
    const headerImage = defaultImage;
    // Destructure agentId from props if available
    let { id, tab } = useParams();
    const [agent, setAgent] = useState(null);
    const [activeTab, setActiveTab] = React.useState(tab ?? "chat");
    const {setLoading} = useLoading(true);
    const [showAddContent, setShowAddContent] = useState(false);
    const [showApiKey, setShowApiKey] = useState(false);

    const redraw = () => {
        setModificationIndex(modificationIndex + 1);
    }

    useEffect(() => {}, [modificationIndex]);

    const fetchAgent = async () => {
        try {
            const response = await fetchAgentRequest({id: id});
            if ("error" in response) {
                toast.error("Failed to fetch agent data: " + response.error);
            } else {
                setAgent(response);
            }
        } catch (e) {
            toast.error("Failed to fetch agent data: " + e);
        }
        setLoading(false);
    };

    useEffect(() => {
        // Fetch agent details only if agentId is available
        if (id) {
            console.log("AgentInfo useEffect: ", id);
            fetchAgent();
        }
    }, [id]);

    function renderTab(eventKey, title, content) {
        return (
            <Tab eventKey={eventKey} title={title} style={{color: "#b29175"}}>
                <div style={{backgroundColor: "#282828", padding: 16, border: "1px white", minHeight: windowHeight - 500}}>
                    {content}
                </div>
            </Tab>
        );
    }


    async function addContentSet(contentSet) {
        for(let i = 0; i < contentSet.length; i++) {
            const messageRole = contentSet[i].role;
            const messageContent = contentSet[i].content;
            const result = await addContentRequest({
                "agent": agent.name,
                "namespace": agent.namespace,
                "role": messageRole,
                "content": messageContent
            })
            if(result.error) {
                toast.error("Failed to add content: " + result.error);
                return;
            }
        }

        setModificationIndex(modificationIndex + 1);
    }

    function pasteAgentContent() {
        navigator.clipboard.readText().then(
            clipText => {
                try {
                    const clipMessages = JSON.parse(clipText);
                    if (clipMessages && clipMessages.length > 0) {
                        addContentSet(clipMessages);
                    }
                } catch (e) {
                    toast.error("Invalid clipboard content");
                }
            }
        )
    }

    function copyAgentContent() {
        // Copy messages to clipboard
        //navigator.clipboard.writeText(JSON.stringify(messages));
    }

    const leftMenu = [
        {icon: MdCopyAll, label: "", onClick: copyAgentContent, tooltip: "Copy Agent Content"},
        {icon: MdContentPaste, label: "", onClick: pasteAgentContent, tooltip: "Paste Agent Content"},
        {icon: MdNewLabel, label: "", onClick: pasteAgentContent, tooltip: "Paste Agent Content"},
    ];

    const rightMenu = [{
        icon: MdNoteAdd,
        tooltip: "Open agent up as a canvas",
        label: "Canvas",
        onClick: () => {window.open(`/gpt/canvas/${id}`, '_blank')}
    }];

    if (activeTab === "content") {
        leftMenu.push({
            icon: MdAdd,
            tooltip: "Add a new message to the base agent message history",
            label: "Add Message", onClick: () => {setShowAddContent(true)}});
    }

    if(activeTab === "properties") {
        rightMenu.push({
            icon: MdVpnKey,
            label: "Add Key", onClick: () => {setShowApiKey(true)}})
    }

    return (
        <PageHeader image={headerImage} title={agent?.name}
                    description={"Design agents to handle gpt prompts in specific ways"}
                    breadcrumb={[
                        ["Home", "/"],
                        ["GPT", "/gptinfo"],
                        ["Agents", "/gpt/agent"],
                        ["Agent Design", `/gpt/agent/${id}`]
                    ]}
                    menuleft={leftMenu}
                    menuright={rightMenu}
                    minimum={true}
                    microfooter={true}
                    stickyfooter={true}
        >
        <div>
            {agent ? <>
                    <Tabs activeKey={activeTab} defaultActiveKey="variables" id="uncontrolled-tab-example"
                          onSelect={key => setActiveTab(key)}>
                        {renderTab("chat", "Chat", <ChatTab agent={agent} />)}
                        {renderTab("content", "Content", <AgentContentTab agent={agent} />)}
                        {/*renderTab("graph", "Graph", () => <GraphTab agent={agent} />)*/}
                        {renderTab("properties", "Properties", <AgentPropertiesTab agent={agent} onAgentUpdated={() => fetchAgent()} />)}
                    </Tabs>

                    <AddContentDialog />
                </>
                : <>Agent not found.</>
            }
        </div>

            <AddContentDialog agent={agent} show={showAddContent} onClose={() => setShowAddContent(false)} onAdd={addContentSet} />
            <AddApiKeyDialog agent={agent} show={showApiKey} onClose={() => setShowApiKey(false)} onApiKeyCreated={() => redraw()} />
        </PageHeader>
    );
}

export default AgentInfo;
