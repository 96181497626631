import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {MdAdd} from "react-icons/all";
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import GridSlideshow, {GridItem} from "../../components/gridslideshow/GridSlideshow";
import 'reactflow/dist/style.css';
import {useEndpoint} from "../../../hooks/api";
import {Grid} from "../../components/imagegrid/grid";


const GridGallery = () => {
    const navigate = useNavigate();
    const {fetch: fetchImages} = useEndpoint("art");
    const [images, setImages] = useState([]);
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // Get model from url params
    const urlParams = new URLSearchParams(window.location.search);
    const model = urlParams.get('model') ?? "";

    useEffect(() => {
        async function fetch() {
            const images = await fetchImages({
                q: [
                    "model=" + model
                ],
                limit: 100,
                sort: "timestamp",
                order: "desc",
                ultrafast: true
            });
            console.log(images);
            const urls = images.map(i => ({ url: i.url, aspect: i.aspect_ratio }));
            console.log(urls);
            setImages(urls);
        }

        fetch();
    }, []);


    return (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh' }}>
            <Grid approxCell={100} width={screenSize.width} height={screenSize.height}>

            </Grid>
        </div>
    );
}

export default GridGallery