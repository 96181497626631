import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Color from "color";
import ButtonControl from "./ButtonControl";
import {artApiFetchAuthAsync} from "../../../../../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";
import {BsFillHandThumbsDownFill, BsFillHandThumbsUpFill} from "react-icons/bs";
import {getRating, setRating} from "../../../../../../hooks/ImageRating";
import {toast} from "react-toastify";
import {Button, Modal} from "react-bootstrap";
import {useEndpoint} from "../../../../../../hooks/api";

const LightboxHeader = ({ images, currentIndex, onClose }) => {
    const image = images[currentIndex];
    const [ thumbsUp, setThumbsUp ] = React.useState(false);
    const [ thumbsDown, setThumbsDown ] = React.useState(false);

    const [showReport, setShowReport] = React.useState(false);
    const { fetchAuth: reportRequest, user } = useEndpoint("report");

    useEffect(() => {
        async function fetch() {
            console.log("Fetching rating: ", image);
            const r = await getRating(image.id, user);
            setThumbsUp(r > 0);
            setThumbsDown(r < 0);
        }

        fetch();
    }, [image, user]);

    useEffect(() => {}, [thumbsUp, thumbsDown]);

    async function Rate(rating) {
        const r = await setRating(image.id, user, rating);
        setThumbsUp(r > 0);
        setThumbsDown(r < 0);
    }

    async function report() {
        await reportRequest({id: image.id});
        toast.info("Reported image. Thank you for your feedback.");
        setShowReport(false);
    }
    const handleClose = () => setShowReport(false);
    const handleShow = () => setShowReport(true);


    function drawRatingButtons() {
        return (
            <div className="d-flex">
                {thumbsDown ?
                    (
                        <div className='thmbsdown thmbsdown-filled'>
                            <span className="shine"></span>
                            <span><BsFillHandThumbsDownFill/></span>
                        </div>
                    ) : (
                        <div className='thmbsdown' onClick={() => Rate(-1)}>
                            <span className="shine"></span>
                            <span><BsFillHandThumbsDownFill/></span>
                        </div>
                    )
                }
                {thumbsUp ? (
                    <div className='thmbsup thmbsup-filled'>
                        <span className="shine"></span>
                        <span><BsFillHandThumbsUpFill/></span>
                    </div>
                ) : (
                    <div className='thmbsup' onClick={() => Rate(1)}>
                        <span className="shine"></span>
                        <span><BsFillHandThumbsUpFill/></span>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div>
            <TopHeaderBar className={"create-card-flex-between-container"}>
                <div  style={{"width": "100px"}}>
                    <PageIndicator>
                        {currentIndex + 1} / {images.length}
                    </PageIndicator>
                </div>
                <div className={"create-card-flex-between-items-grow"}/>
                <div>
                    {drawRatingButtons()}
                    <div className={"row justify-content-center align-items-center"}>
                        <span style={{cursor: "pointer", fontSize: "10px"}} onClick={() => setShowReport(true)}>Report</span>
                    </div>
                </div>
                <div className={"create-card-flex-between-items-grow"}/>
                <div  style={{"width": "100px"}}>
                    <CloseButton className='closeL' onClick={onClose} type="button">
                        <i className="fa fa-close"></i>
                    </CloseButton>
                </div>
            </TopHeaderBar>


            <Modal show={showReport} onHide={handleClose} style={{zIndex: 3500}}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to report this image?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={report}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

LightboxHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired
};

export default LightboxHeader;


const PageIndicator = styled.span`
  white-space: nowrap;
  min-width: 60px;
  text-align: center;
`;

const RightSideContainer = styled.div`
  width: 117px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(ButtonControl)`
  height: 100%;
  display: flex;
  border-left-color: ${({ theme }) => theme.headerNavFontColor};
  color: inherit;
`;



const TopHeaderBar = styled.header`
  z-index: 10;
  cursor: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 2px 10px 20px;
  color: ${({ theme }) => theme.headerNavFontColor};
  background-color: ${({ theme }) =>
    Color(theme.pageBackgroundColor)
      .alpha(0.5)
      .hsl()
      .string()};
  > * {
    height: inherit;
  }
`;
