import React, {useEffect, useState} from 'react';
import './chat-style-overrides.scss';
import { ConfigProvider, theme } from "antd";
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import { useAuth0 } from "@auth0/auth0-react";
import { Controlled as CodeMirror } from 'react-codemirror2';
import { JsonEditor } from 'jsoneditor-react18';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'jsoneditor-react18/es/editor.min.css';
import { Row, Col } from 'react-bootstrap';
import AgentChatWindow from "../../components/chat/agentChatWindow";
import useWindowDimensions from '../../../hooks/WindowDimensions';
import {useParams} from "react-router-dom";
import {artApiFetchAsync, artApiFetchAuthAsync} from "../../../hooks/artapi";
import useAgents from "./agenthook";
import {useIndexDB} from "../../providers/indexdb";
import {useEntitlements} from "../../../hooks/entitlements";
import './canvas.css';
import {useEndpoint} from "../../../hooks/api";

const AgentCanvas = () => {
    const [editorContent, setEditorContent] = useState('');
    const [isJson, setIsJson] = useState(false);
    const { height: windowHeight, width: windowWidth } = useWindowDimensions();
    const [ agent, setAgent ] = useState(undefined);
    const params = new URLSearchParams(window.location.search);
    const {key} = useParams();
    const [agentKey, setAgentKey] = useState(key ?? params.get('key'));
    const { agents, namespaceList, agentCount } = useAgents();
    const { getValue, setValue } = useIndexDB();
    const { isEntitled, entitlements, isLoadingEntitlements } = useEntitlements();
    const [lastMessage, setLastMessage] = useState('');
    const viewHeight = windowHeight - 96*2 -32;
    const {fetch: fetchAgentFromKey} = useEndpoint("gpt/agent-info");
    const {fetchAuth: fetchAgentFromId} = useEndpoint("gpt/agent-info");

    useEffect(() => {

    }, [agentCount]);

    useEffect(() => {
        async function init() {
            if(agentKey) {
                console.log("Loading agent from key: ", agentKey);
                const agentResponse = await fetchAgentFromKey({"key": agentKey});
                if(agentResponse && !agentResponse.error) {
                    setAgent(agentResponse);
                } else {
                    const agentResponse = await fetchAgentFromId({"id": agentKey});
                    if(agentResponse && !agentResponse.error) {
                        setAgent(agentResponse);
                    } else {
                        setAgent(undefined);
                    }
                }
                return;
            }

            const agent = await getValue("agent-chat::selected-agent", null);
            if(agent) {
                setAgent(agent);
            }
        }

        init();
    }, []);

    useEffect(() => {}, [agent]);

    function onAgentSelected(agent) {
        setValue("agent-chat::selected-agent", agent);
        setAgent(agent);
    }

    const handleEditorChange = (value) => {
        if(value === lastMessage) return;
        setLastMessage(value);
        setEditorContent(value);
        try {
            JSON.parse(value);
            setIsJson(true);
        } catch (e) {
            setIsJson(false);
        }
    };

    function content() {
        return agent && (
            <AgentChatWindow
                agent={agent}
                agentKey={agentKey}
                scroll={true}
                height={windowHeight - 425}
                onPostProcessMessage={(message) => {
                    if(!message) return;
                    // Grab the first text surrounded by ``` and ``` and make it a code block
                    const codeBlockRegex = /```([^`]+)```/g;
                    const codeBlockMatches = message.match(codeBlockRegex);
                    if (codeBlockMatches) {
                        // Only match the first
                        const code = codeBlockMatches[0].replace(/```/g, '');
                        message = message.replace(codeBlockMatches[0], '');
                        handleEditorChange(code);
                    }
                    return message;
                }}
            />
        );
    }

    function rightColumn() {
        return (
            <div className="editor-container cm-editor" style={{ height: '100%', overflow: 'auto', flex: 2, position: 'relative' }}>
                {isJson ? (
                    <JsonEditor
                        value={JSON.parse(editorContent || '{}')}
                        onChange={(json) => setEditorContent(JSON.stringify(json, null, 2))}
                    />
                ) : (
                    <CodeMirror
                        value={editorContent}
                        options={{
                            mode: 'string',
                            lineNumbers: true,
                            theme: 'material' || "dark",
                        }}
                        onBeforeChange={(editor, data, value) => {
                            handleEditorChange(value);
                        }}
                    />
                )}
            </div>
        );
    }

    return (
        <PageHeader image={"/img/headers/agent-banner-wide.png"}
                    title={agent?.name ? "Agent Canvas - " + agent.name : "Agent Canvas"}
                    description={"Work to alter a single script or body of work"}
                    breadcrumb={[
                        ["Home", "/"],
                        ["GPT", "/gptinfo"],
                    ]}
                    microfooter={true}
                    minimum={true}>

            <Row style={{
                // Absolute positioning to fill the page
                position: "fixed",
                top: 96+16,
                left: 64+16,
                right: 32,
                bottom: 96+16,
                overflow: "hidden"
            }}>
                <Col className={"left-column"}>{content()}</Col>
                <Col className={"right-column"} style={{backgroundColor: "rgba(38,50,56,0.85)", maxHeight: viewHeight}}>
                    {rightColumn()}
                </Col>
            </Row>
        </PageHeader>
    )
};

export default AgentCanvas;
