import React, { useState } from 'react';
import './presetgrid.css';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

function PresetTile({ name, description }) {
    return (
        <div className="grid-box">
            <div className="grid-box-content">
                <div className="grid-title">{name}</div>
                {description && <p className="grid-description">{description}</p>}
            </div>
        </div>
    );
}

function PresetsGrid({
                         title = "Items",
                         itemType = "item",
                         items = [],
                         onGetName = (item) => item.name,
                         onGetCategories = (item) => [item.category || "General"],
                         onCreateTile = (item) => <PresetTile name={onGetName(item)} description={item.description} />
                     }) {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    // Categorize items based on the provided onGetCategories function
    const categorizedItems = items.reduce((categories, item) => {
        const categoryHierarchy = onGetCategories(item);
        const mainCategory = categoryHierarchy[0];
        if (!categories[mainCategory]) {
            categories[mainCategory] = [];
        }
        categories[mainCategory].push({ item, categoryHierarchy });
        return categories;
    }, {});

    // Sort categories and items within categories
    const sortedCategories = Object.keys(categorizedItems).sort((a, b) => {
        if (a === 'General') return -1;
        if (b === 'General') return 1;
        return a.localeCompare(b);
    });

    const filteredCategories = sortedCategories.reduce((filtered, category) => {
        const filteredItems = categorizedItems[category]
            .filter(({ item }) => onGetName(item).toLowerCase().includes(searchTerm.toLowerCase()))
            .sort((a, b) => onGetName(a.item).localeCompare(onGetName(b.item)));
        if (filteredItems.length > 0) {
            filtered[category] = filteredItems;
        }
        return filtered;
    }, {});

    function handleClick(item) {
        if (item.link) navigate(item.link);
        if (item.onClick) item.onClick();
    }

    return (
        <div className="presets-grid">
            <h2>{title}</h2>
            <Form.Control
                type="text"
                placeholder={`Search ${itemType}...`}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="mb-3"
            />
            {Object.keys(filteredCategories).map((category, index) => (
                <div key={index} className="category-section">
                    {filteredCategories[category][0].categoryHierarchy.map((cat, depth) => (
                        <h4 key={depth} className={depth > 0 ? "subcategory-title" : ""} style={{ fontSize: `${24 - depth * 2}px` }}>{cat}</h4>
                    ))}
                    <div className="grid">
                        {filteredCategories[category].map(({ item }, itemIndex) => (
                            <div key={itemIndex} onClick={() => handleClick(item)}>
                                {onCreateTile(item)}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default PresetsGrid;