import React, { useState } from 'react';
import { Motion, spring } from 'react-motion';

function AnimatedImage({ src, alt, onClick }) {
    const [loaded, setLoaded] = useState(false);

    const defaultStyle = {
        opacity: 0,
        scale: 0.8
    };

    const style = {
        opacity: spring(loaded ? 1 : 0),
        scale: spring(loaded ? 1 : 0.8)
    };

    return (
        <Motion defaultStyle={defaultStyle} style={style}>
            {interpolatingStyle => (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        position: 'relative'
                    }}
                >
                    <img
                        onClick={onClick}
                        src={src}
                        alt={alt}
                        style={{
                            opacity: interpolatingStyle.opacity,
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: `translate(-50%, -50%) scale(${interpolatingStyle.scale})`,
                            transition: 'opacity 0.5s ease-in-out'
                        }}
                        onLoad={() => setLoaded(true)}
                        onError={() => setLoaded(false)}
                    />
                </div>
            )}
        </Motion>
    );
}

export default AnimatedImage;
