import React from 'react';
import './agenttile.css';

function AgentTile({ agent, onClick }) {
    return (
        <div className="agent-box" onClick={() => onClick(agent)}>
            <div className="agent-box-content">
                <h3 className="agent-name">{agent.name}</h3>
                <p className="agent-description" title={agent.description}>
                    {agent.description}
                </p>
            </div>
        </div>
    );
}

export default AgentTile;
