import React from 'react';
import { JsonEditor } from 'jsoneditor-react18';
import CodePreview from '../codepreview/codepreview';
import ReactMarkdown from 'react-markdown';

const CodeBlock = ({ language, value }) => (
    <CodePreview expanded={true} code={value} language={language} style={language === 'plaintext' ? { whiteSpace: 'pre-wrap', wordBreak: 'break-word' } : {}} />
);

const parseText = (text) => {
    const regex = /```(\w+)?\n([\s\S]*?)\n```/g;
    let result;
    const parts = [];
    let lastIndex = 0;

    while ((result = regex.exec(text)) !== null) {
        const language = result[1] || 'plaintext';
        const start = result.index;
        const end = regex.lastIndex;
        const code = result[2].trim();

        // Push text before the code block
        if (start > lastIndex) {
            parts.push({ isCode: false, text: text.slice(lastIndex, start) });
        }
        // Push the code block
        parts.push({ isCode: true, language, code });

        lastIndex = end;
    }

    // Push remaining text
    if (lastIndex < text.length) {
        parts.push({ isCode: false, text: text.slice(lastIndex) });
    }

    return parts;
};

const isJson = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch {
        return false;
    }
};

const Message = ({ content }) => {
    if (!content) return null;

    if (isJson(content)) {
        return (
            <JsonEditor
                value={JSON.parse(content)}
                mode="view"
                style={{ height: '200px' }}
            />
        );
    }

    const parts = parseText(content);

    return (
        <div>
            {parts.map((part, index) =>
                part.isCode ? (
                    <CodeBlock key={`code-${index}`} language={part.language} value={part.code} />
                ) : (
                    <ReactMarkdown key={`text-${index}`}>{part.text}</ReactMarkdown>
                )
            )}
        </div>
    );
};

export default Message;
