import React, {forwardRef, useImperativeHandle} from 'react';

const Grid = forwardRef(({ width, height, ...props }, ref) => {
    const cellSize = Math.min(parseInt(width), parseInt(height)) / 10;
    const horizontalCells = Math.floor(width / cellSize) + 1;
    const verticalCells = Math.floor(height / cellSize);

    const gridTracker = Array.from({ length: verticalCells }, () => Array(horizontalCells).fill(0));

    const reserve = (x, y, cellWidth, cellHeight) => {
        for (let i = y; i < y + cellHeight; i++) {
            for (let j = x; j < x + cellWidth; j++) {
                if (i < verticalCells && j < horizontalCells) {
                    gridTracker[i][j] = 1;
                }
            }
        }
    }

    const getBestDimensionsForAspectRatio = (aspectRatio) => {
        const space = getNextLargestSpace();

        if (aspectRatio === 0) aspectRatio = 1;

        let width = 0;
        let height = 0;
        if(aspectRatio > 1) {
            width = space.cellWidth;
            height = Math.round(width / aspectRatio);

            // If height > space.cellHeight scale down
            if(height > space.cellHeight) {
                height = space.cellHeight;
                width = Math.round(height * aspectRatio);
            }
        } else if (aspectRatio == 1) {
            width = Math.min(space.cellWidth, space.cellHeight);
            height = width;
        } else {
            height = space.cellHeight;
            width = Math.round(height * aspectRatio);
            if(width > space.cellWidth) {
                width = space.cellWidth;
                height = Math.round(width / aspectRatio);
            }
        }

        const result = { x: space.x, y: space.y, width, height };
        console.log(`Result dimension: ${aspectRatio}`, result, space);
        return result;
    }

    const getNextLargestSpace = () => {
        let maxArea = 0;
        let bestFit = { cellWidth: 0, cellHeight: 0, x: -1, y: -1 };

        for (let i = 0; i < verticalCells; i++) {
            for (let j = 0; j < horizontalCells; j++) {
                if (gridTracker[i][j] === 0) {
                    let width = 0, height = 0;
                    while (j + width < horizontalCells && gridTracker[i][j + width] === 0) width++;
                    while (i + height < verticalCells && gridTracker[i + height][j] === 0) height++;

                    if (width * height > maxArea) {
                        maxArea = width * height;
                        bestFit = { cellWidth: width, cellHeight: height, x: j, y: i };
                    }
                }
            }
        }

        return bestFit;
    };

    const hasAvailableSpace = (gridTracker) => {
        for (let i = 0; i < gridTracker.length; i++) {
            for (let j = 0; j < gridTracker[i].length; j++) {
                if (gridTracker[i][j] === 0) {
                    return true; // Found an unoccupied cell
                }
            }
        }
        return false; // No unoccupied cells found
    }

    const clear = () => {
        for (let i = 0; i < gridTracker.length; i++) {
            for (let j = 0; j < gridTracker[i].length; j++) {
                gridTracker[i][j] = 0;
            }
        }
    }

    useImperativeHandle(ref, () => ({
        reserve,
        clear,
        getNextLargestSpace,
        getBestDimensionsForAspectRatio,
        hasAvailableSpace: () => hasAvailableSpace(gridTracker),
        gridWidth: () => horizontalCells,
        gridHeight: () => verticalCells,
        cellSize: () => cellSize
    }));

    const remainderWidth = width - (horizontalCells * cellSize);
    const remainderHeight = height - (verticalCells * cellSize);
    const marginLeft = -remainderWidth / 2;
    const marginTop = -remainderHeight * 1.5;

    const gridBackground = `
        repeating-linear-gradient(
            0deg,
            transparent,
            transparent ${cellSize - 4}px,
            black ${cellSize - 4}px,
            black ${cellSize}px
        ),
        repeating-linear-gradient(
            90deg,
            transparent,
            transparent ${cellSize - 4}px,
            black ${cellSize - 4}px,
            black ${cellSize}px
        )
    `;

    return (
        <div style={{
            ...props.style,
            display: 'grid',
            width: width + remainderWidth,
            height: height + cellSize,
            gridTemplateColumns: `repeat(${horizontalCells}, ${cellSize}px)`,
            gridTemplateRows: `repeat(${verticalCells}, ${cellSize}px)`,
            position: 'relative',
            marginLeft: `${marginLeft}px`,
            marginTop: `${marginTop}px`
        }}>
            {props.children}
        </div>
    );
});

const Cell = ({ x, y, cellWidth, cellHeight, ...props }) => {
    const cellStyle = {
        gridColumnStart: x + 1,
        gridColumnEnd: x + cellWidth + 1,
        gridRowStart: y + 1,
        gridRowEnd: y + cellHeight + 1,
        margin: 4,
        boxSizing: 'border-box',
        overflow: 'hidden',
    };
    return <div style={cellStyle} {...props}></div>;
};

export { Grid, Cell };
