import React, {forwardRef, useImperativeHandle, useEffect, useState} from 'react';
import {Form, Button, Table} from 'react-bootstrap';
import {MdAdd, MdDelete} from 'react-icons/md';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync, useAuthenticatedArtApi} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import ObfuscatedKey from "../obfuscatedkey";
import StyledButton from "../styledbutton";
import TermsOfServiceModal from "./termsofservice";

const ServiceList = forwardRef(( props, ref ) => {
    const { response: userInfo, error: userError } = useAuthenticatedArtApi("user-info");
    const [keys, setKeys] = useState([]);
    const [keyTypes, setKeyTypes] = useState([]);
    const [newApiKey, setNewApiKey] = useState("");
    const [newType, setNewType] = useState("");
    const { fetchAuth: listServicesRequest } = useAuthenticatedArtApi("services/list-services");
    const { fetchAuth: fetchKeyTypesRequest } = useAuthenticatedArtApi("services/service-types");
    const { fetchAuth: deleteKeyRequest } = useAuthenticatedArtApi("services/delete-key");
    const { fetchAuth: setKeyRequest } = useAuthenticatedArtApi("services/set-key");

    useImperativeHandle(ref, () => ({
        refresh() {
            fetchKeys();
        }
    }) );

    async function fetchKeys() {
        if (userInfo) {
            const keys = await listServicesRequest();
            setKeys(Object.values(keys));
        }
    }

    async function fetchKeyTypes() {
        if (userInfo) {
            const keyTypes = await fetchKeyTypesRequest();
            if(keyTypes.error) {
                console.log("Error fetching key types: ", keyTypes.error);
                return;
            }
            setKeyTypes(keyTypes);
        }
    }

    useEffect(() => {
        fetchKeyTypes();
        fetchKeys();
        fetchKeyTypes();
    }, [userInfo]);
    async function refreshAccess() {
        await fetch("https://server.doubtech.com/server-to-server/refresh-access", {mode: 'no-cors'});
    }
    function handleDelete(type) {
        async function deleteKey(type) {
            const result = await deleteKeyRequest({type: type});
            if('error' in result) {
                toast.error(result.error);
            } else {
                fetchKeys();
            }
            await refreshAccess();
        }

        deleteKey(type);
    }

    function submitKey(key, value = undefined) {
        if(value) key.apikey = value;
        async function updateKey() {
            const result = setKeyRequest({
                apikey: value,
                type: key.type
            });
            if('error' in result) {
                toast.error(result.error);
            } else {
                fetchKeys();
            }
            await refreshAccess();
        }

        updateKey();
        setKeys(keys);
    }

    function drawAddService() {
        // Return a dropdown that iterates over the keyTypes and allows the user to add a new service
        return (<>
            <tr>
                <td>
                    <Form>
                    <Form.Group>
                        <Form.Control as="select" onChange={event => {
                            setNewType(event.target.value);
                        }}>
                            <option value={""}>Select a service</option>
                            {keyTypes && keyTypes.map(keyType => {
                                return (<option key={keyType.type} value={keyType.type}>{keyType.display_name}</option>);
                            })}
                        </Form.Control>
                    </Form.Group>
                    </Form>
                </td>
                <td>
                    <ObfuscatedKey keyString={newApiKey}
                                   showCopy={false}
                                   showPaste={true} onTokenSet={(value) => setNewApiKey(value)}
                    />
                    <br/>
                    <div className={"create-card-flex-between-container"}>
                        <div className={"create-card-flex-between-items-grow"}/>
                        <StyledButton onClick={() => {
                            submitKey({type: newType}, newApiKey);
                            setNewApiKey("");
                            setNewType("");
                        }} label={"Add Service"}/>
                    </div>
                </td>
            </tr>
        </>);
    }

    return (
        <><Table striped bordered hover variant="dark">
            <thead>
            <tr>
                <th>Name</th>
                <th>Token</th>
            </tr>
            </thead>
            <tbody>
            {keys && keys.map(key => {
                return (<tr key={key.apikey}>
                    <td>{key.display_name}</td>
                    <td><ObfuscatedKey keyString={key.apikey}
                                       showCopy={false}
                                       showPaste={true} onTokenSet={(value) => submitKey(key, value)}
                                       onDelete={() => handleDelete(key.type)}
                    /></td>
                </tr>);
            })}

            {keyTypes && drawAddService()}
            </tbody>
        </Table>
        </>
    );
});

export default ServiceList;
