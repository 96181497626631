import React, {useEffect, useState} from 'react';
import {MdNewLabel} from "react-icons/md";
import {toast} from "react-toastify";
import {useAuth0} from "@auth0/auth0-react";
import {
    artApiFetchAuthAsync,
    useAuthenticatedArtApi
} from "../../../hooks/artapi";
import {Button, Card, Dropdown, DropdownButton, Form, ListGroup, Tab, Tabs} from "react-bootstrap";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import {CollapsibleCard, CollapsibleCardContent} from "../../components/CollapsableCard";
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import {MdAdd} from "react-icons/all";
import useAgents from "./agenthook";
import {useParams} from "react-router-dom";
import {useEndpoint} from "../../../hooks/api";
import AgentGrid from "../../components/agent/agentgrid/agentgrid";
import AddAgentDialog from "../../components/agent/agentinfo/AddAgentDialog";
import {useLoading} from "../../components/loading";
import PresetsGrid from "../../components/presetgrid/presetsgrid";
import AgentTile from "../../components/agent/agenttile/agenttile";
import {useNavigate} from 'react-router-dom';


const AgentDesign = () => {
    const navigate = useNavigate();
    let { id, tab } = useParams();
    const { getAccessTokenSilently, user } = useAuth0();
    const { response: entitlements, error: entitlementError } = useAuthenticatedArtApi("entitlements-list");
    const { response: userInfo, error: userError } = useAuthenticatedArtApi("user-info");
    const defaultImage = '/img/headers/agent.png';
    var headerImage = defaultImage;
    const [ agent, setAgent ] = React.useState(null);
    const [ messages, setMessages ] = React.useState([]);

    const [responseValue, setResponseValue] = React.useState('');
    const [createNamespace, setCreateNamespace] = React.useState(false);
    const [createAgent, setCreateAgent] = React.useState(false);
    const [newNamespaceName, setNewNamespaceName] = React.useState('');
    const [ conversationHistory, setConversationHistory ] = React.useState([]);

    // Add Modal for Add Agent
    const [showAddAgent, setShowAddAgent] = useState(false);

    // Add Modal for Add Content

    // Get Window Width
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const { agents, agentsList, namespaces, fetchNamespaces, isLoadingAgents } = useAgents();
    const {setLoading} = useLoading(true);

    useEffect(() => {
        setLoading(isLoadingAgents)
    }, [isLoadingAgents]);


    const { fetchAuth: fetchAgent } = useEndpoint("gpt/agent");

    function handleCreateNamespace() {
        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiFetchAuthAsync(token, "gpt/create-namespace", "namespace=" + newNamespaceName);
            // if error in response, show error
            if("error" in messageResponse) {
                toast.error(messageResponse.error);
            } else {
                toast.success("Namespace created.\n" + newNamespaceName);
                setNewNamespaceName('');
                setCreateNamespace(false);
            }
            await fetchNamespaces();
        }

        fetchResponse();
    }

    function drawAgents(namespace) {
        return namespace.map(agent =>
            <ListGroup.Item style={{cursor: "pointer"}}
                        tooltip={agent.description}
                        key={agent.id}
                        onClick={() => fetchAgent()}>
            {agent.name}
            <br/>
            <span style={{fontSize: 8}}>{agent.namespace}</span>
        </ListGroup.Item>);
    }

    function drawNamespace(namespace) {
        return <CollapsibleCard key={namespace} title={namespace[0].namespace}>
            <CollapsibleCardContent>
                {drawAgents(namespace)}
            </CollapsibleCardContent>
        </CollapsibleCard>
    }

    function renderAgents() {
        return <Card>
            <Card.Header>
                <div className={"create-card-flex-between-container"}>
                    <span>Agents</span>
                </div>
            </Card.Header>
            <ListGroup variant="flush">
                {agents && Object.values(agents).map(namespace => {
                    if(agents.length == 1) {
                        return (drawAgents(namespace))
                    } else {
                        return (drawNamespace(namespace));
                    }
                })}
            </ListGroup>
        </Card>;
    }

    function getAgents() {
        return Object.values(agents).map(namespace => {
            return {
                label: namespace
            };
        });
    }

    function renderNamespaces() {
        return <Card className={"mt-5"}>
            <Card.Header>
                <div className={"create-card-flex-between-container"}>
                    <span>Namespaces</span>
                    <MdNewLabel className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {
                        setCreateNamespace(!createNamespace)
                    }} />
                </div>
            </Card.Header>
            <ListGroup variant="flush">
                {namespaces && namespaces.map((agent, index) =>
                    <ListGroup.Item style={{cursor: "pointer"}}
                                    key={index}>
                        {agent.namespace}
                    </ListGroup.Item>)}
            </ListGroup>
        </Card>;
    }

    function rightColumn() {

        return <>




            {createNamespace && <Card>
                <Card.Header>
                    <div className={"create-card-flex-between-container"}>
                        <span>Create Namespace</span>
                    </div>
                </Card.Header>

                <Card.Body>
                    <Form onSubmit={handleCreateNamespace}>
                        <Form.Group controlId="content">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control value={newNamespaceName} onChange={(event) => setNewNamespaceName(event.target.value)} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Create
                        </Button>
                    </Form>
                </Card.Body>
            </Card>}
        </>;
    }

    function content() {
        return (
        <>
            {agent && <div className='content'>





            </div>}
        </>);
    }

    function handleClick(agent) {
        navigate(`/gpt/agent/${agent.id}`);
    }

    return (
    <PageHeader image={headerImage} title={agent ? agent.name : "GPT Agent Design"}
                description={"Design agents to handle gpt prompts in specific ways"}
                breadcrumb={[
                    ["Home", "/"],
                    ["GPT", "/gptinfo"],
                    ["Agents", "/gpt/agent"]
                ]}
                menuright={[
                    {icon: MdAdd, label: "Add Agent", onClick: () => setShowAddAgent(true)}
                ]}
                minimum={true}
                stickyfooter={true}
    >
        {agents ? <PresetsGrid items={agentsList}
                               onGetCategories={(agent) => [agent.namespace]}
                               onGetName={(agent) => agent.name}
                               onCreateTile={(agent) => <AgentTile agent={agent} onClick={() => handleClick(agent)} />}
        /> : <span>Create or select an agent to get started.</span>}

        <AddAgentDialog show={showAddAgent}
             onCreateAgent={() => setShowAddAgent(false)}
             onClose={() => setShowAddAgent(false)} />
    </PageHeader>);
}

export default AgentDesign;