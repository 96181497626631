import React, {useCallback, useEffect} from "react";
import {
    artApiFetchAsync,
    artApiFetchAuthAsync, artApiFetchAuthAsyncWithToast, fetchGpt,
} from "../../../../../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";
import TagList from "../../../../taglist";
import {MdAddBox, MdFilter, MdShare, MdTag} from "react-icons/all";
import {toast} from "react-toastify";
import Form from "react-bootstrap/Form";
import {MdAdd, MdShuffle} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {useImageSelection} from "../../../../../providers/selection";
import {useGptAgent} from "../../../../../../hooks/gptagent";
import {useEndpoint} from "../../../../../../hooks/api";

const GalleryFooter = ({ image }) => {
    const [addTag, setAddTag] = React.useState(false);
    const [newTags, setNewTags] = React.useState("");
    const [generationId, setGenerationId] = React.useState(0);
    const navigate = useNavigate();
    const {addSelectedImage} = useImageSelection();
    const { fetchAuth: reportRequest } = useEndpoint("report");
    const { fetchAuth: addTagRequest } = useEndpoint("tag/add");
    const { fetch: rateRequest, user } = useEndpoint("rate");
    const {postPrompt} = useGptAgent(
        "aiart",
        "Tag Generator");

    useEffect(() => {}, [generationId]);

    let gridItems = [];
    if(image.source && image.source.gridWidth && image.source.gridWidth > 0) {
        let gridWidth = image.source.gridWidth;
        let gridHeight = image.source.gridHeight;
        let index = 0;
        for (let i = 0; i < image.source.gridWidth; i++) {
            for (let j = 0; j < image.source.gridHeight; j++) {
                gridItems.push(`https://api.aiart.doubtech.com/image?grid=${index}@${gridWidth}x${gridHeight}&url=${image.src}`);
                index++;
            }
        }
    }

    const handleCopyLink = useCallback(() => {
        const url = new URL(`/image/${image.id}`, window.location.origin);
        navigator.clipboard.writeText(url.href).then(() => {
            toast.info('Link copied to clipboard!');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }, [image]);

    async function Report() {
        console.log("Report image: ", image);
        await reportRequest({id: image.id});
        window.location.reload(false);
    }

    async function Rate(rating) {
        await rateRequest({id: image.id, rating: rating, user: user.email});
    }

    async function addTags() {
        const tags = newTags.split(",").map(tag => tag.trim().toLowerCase());
        if(tags.length > 0) {
            // Create an updatable toast
            const toastId = toast.info("Adding tags to image...", {
                autoClose: false,
            });
            await addTagRequest({
                id: image.id,
                tags: tags.join(","),
            })
            toast.update(toastId, {
                render: "Tags added to image.",
                type: toast.TYPE.SUCCESS,
                autoClose: 3000,
            });
        }
        setGenerationId(generationId + 1);
    }

    async function generateTags() {
        const tags = await postPrompt(image?.prompt,
            "Generating tags...",
            content => "Generated tags: " + content);
        if(tags) setNewTags(tags);

        setGenerationId(generationId + 1);
    }

    function onClickSet() {
        navigate("/set/" + image.set_name);
    }

    function addToSelectedImage() {
        console.log("Add selected image: ", image);
        addSelectedImage(image);
        toast.info("Image added to selection.");
    }

    console.log("Image data: ", image);

    return (
        <div style={{backgroundColor: "#111111", padding: "4px", textAlign: "center"}}>
            <div style={{maxHeight: 200, overflowY: "auto"}}>
                <span>{image?.prompt}</span>
            </div>

            {image.id && <><br/><TagList id={image.id} filter={false}/></>}
            {gridItems.length > 1 && <><br/>
                <Link to={`/grid/${image.id}`}>Grid</Link>
                {gridItems.map((item, index) => <span> <Link to={`/grid/${image.id}/${index}`}>{index + 1}</Link>...</span>)}
                </>}

            <MdAdd onClick={() => addToSelectedImage()} style={{cursor: "pointer"}}/>
            &nbsp;
            <MdShare onClick={handleCopyLink} style={{cursor: "pointer"}}/>
            &nbsp;
            <MdTag onClick={() => setAddTag(!addTag)} style={{cursor: "pointer"}}/>
            &nbsp;
            {image.set_name && <MdFilter onClick={onClickSet} style={{cursor: "pointer"}}/>}
            {addTag && <div className={"create-card-flex-between-container vertical-align"}>
                <MdShuffle className={"md-edit-icon"} onClick={() => generateTags()}/>
                <Form.Control type="text" value={newTags} placeholder="Enter tag values separated by commas" onChange={e =>  setNewTags(e.target.value)} />
                <MdAddBox className={"md-edit-icon"} onClick={() => {
                    addTags();
                }} />
            </div>}
        </div>
    );
/*
    return(
        <motion.div className="dark-overlay m-3"
                initial={{opacity: 0}}
                animate={{opacity: 1}}>
            <div className='m-10 centered'>

                <div className='thmbsdown'  onClick={() => Rate(-1)}>
                    <span className="shine"></span>
                    <span><BsFillHandThumbsDownFill/></span>
                </div>

                <div className='thmbsup' onClick={() => Rate(1)}>
                    <span className="shine"></span>
                    <span><BsFillHandThumbsUpFill/></span>
                </div>
            </div>
            {image.prompt && <section className='container-fluid black_more mt-3'>
                <div className='row m-10-hor'>
                    {image.prompt}
                </div>
            </section>}
            <div className="btnReport">
                <a className="link" onClick={() => Report()}>
                    <span className="shine"></span>
                    <span>Report</span>
                </a>
            </div>
        </motion.div>
    );*/
}

export default GalleryFooter;