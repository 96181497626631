import React, { useState, useEffect } from 'react';

// The Grid component
const Grid = ({ width, height, approxCell = 100 }) => {
    const [cellSize, setCellSize] = useState(approxCell);
    const [columns, setColumns] = useState(1);
    const [rows, setRows] = useState(1);

    useEffect(() => {
        const newColumns = Math.floor(width / approxCell);
        const newRows = Math.floor(height / approxCell);
        const adjustedCellSize = Math.min(
            Math.floor(width / newColumns),
            Math.floor(height / newRows)
        );

        setColumns(newColumns);
        setRows(newRows);
        setCellSize(adjustedCellSize);
    }, [width, height, approxCell]);

    const gridWidth = columns * cellSize;
    const gridHeight = rows * cellSize;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <div
                style={{
                    width: `${gridWidth}px`,
                    height: `${gridHeight}px`,
                    display: 'grid',
                    gridTemplateColumns: `repeat(${columns}, ${cellSize}px)`,
                    gridTemplateRows: `repeat(${rows}, ${cellSize}px)`,
                    gap: '1px',
                    backgroundColor: '#eee',
                    boxSizing: 'border-box',
                    border: '1px solid rgba(0, 0, 0, 0.5)', // Ensures outer edges show borders
                }}
            >
                {[...Array(rows * columns)].map((_, index) => (
                    <div
                        key={index}
                        style={{
                            width: `${cellSize}px`,
                            height: `${cellSize}px`,
                            backgroundColor: 'rgba(200, 200, 255, 0.3)',
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            boxSizing: 'border-box',
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Grid;
